import styled from "@emotion/styled";
import type { FC, ReactNode } from "react";
import React, { useMemo } from "react";
import logo from "src/ui/assets/9amHealth-Logo-9.svg";
import type { LoadingKey } from "src/state/LoadingCubit/LoadingCubit";
import LoadingCubit from "src/state/LoadingCubit/LoadingCubit";
import { useBloc } from "src/state/state";
import { LoadingIndicator } from "src/ui/components/Loader/Loader";
import AvatarCubit from "src/state/AvatarCubit/AvatarCubit";

export const Frame = styled.div`
  width: 44px;
  min-width: 44px;
  height: 44px;
  position: relative;
  z-index: 1;
  border: 0;
  outline: 0;
  line-height: 0;
  border-radius: 50%;
  margin: 0;

  .avatar-border {
    display: block;
    position: absolute;
    --inset: -2px;
    inset: var(--inset);

    border-radius: 50%;
    background: var(--gradient-sunrise);
  }

  .inner {
    position: absolute;
    border-radius: 50%;
    inset: 0;
    z-index: 2;
    background: white;
    overflow: hidden;

    img {
      position: absolute;
      //  cover
      inset: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }

    /* head */
    &::before,
    &::after {
      content: "";
      display: block;
      width: 40%;
      aspect-ratio: 1;
      position: absolute;
      top: 25%;
      left: 50%;
      transform: translate(-50%, 0);
      border-radius: 50%;
      background: #0000001e;
    }

    /* body */
    &::after {
      top: 70%;
      width: 120%;
    }
  }
`;

const FrameContent = styled.div`
  font-weight: 600;
  margin-top: 3px;
  font-size: 1.2rem;
  line-height: 1;
  position: relative;
`;

const Label = styled.label`
  position: absolute;
  display: block;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 10;
  border-radius: 50%;
`;

const Avatar: FC<{
  children?: ReactNode | undefined;
  onClick?: () => void;
  id?: string;
  className?: string;
  background?: string;
  picture?: string;
  border?: boolean;
  edit?: boolean;
  loadingKey?: LoadingKey;
}> = ({ onClick, picture, children, className, id, edit, loadingKey }) => {
  const [, { isLoading }] = useBloc(LoadingCubit);
  const [avatar, { uploadAvatar }] = useBloc(AvatarCubit, {
    create: () => new AvatarCubit(id)
  });

  const loading = loadingKey && isLoading(loadingKey);
  const isSystem = useMemo(() => {
    return id === "SYSTEM";
  }, [id]);

  const image = useMemo(() => {
    if (picture) {
      return picture;
    }

    if (isSystem) {
      return logo;
    }
    return avatar.path;
  }, [isSystem, avatar]);

  return (
    <Frame onClick={onClick} className={className}>
      <div className="avatar-border" />
      <div className="inner">
        {loading ? (
          <LoadingIndicator />
        ) : image ? (
          <>
            <img src={image} alt="" />
          </>
        ) : (
          <>
            {edit && <div />}
            <FrameContent>{children}</FrameContent>
          </>
        )}
        {edit && (
          <Label>
            <input
              hidden
              type="file"
              accept="image/*"
              onChange={(e): void => void uploadAvatar(e)}
            />
          </Label>
        )}
      </div>
    </Frame>
  );
};

export default Avatar;
