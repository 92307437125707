import type { FC } from "react";
import React from "react";
import { ArrowLeftIcon } from "src/constants/icons";
import styled from "@emotion/styled";
import useGoToOrBack from "src/ui/hooks/useGoToOrBack";
import { createPortal } from "react-dom";
import { useBloc } from "src/state/state";
import AppViewCubit from "src/state/AppViewCubit/AppViewCubit";
import Translate from "src/ui/components/Translate/Translate";

const Button = styled.button`
  label: OverlayBackButton;
  position: sticky;
  top: 0;
  left: 0;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 200;
  color: var(--color-charcoal);
  pointer-events: all;
  width: 3rem;
  aspect-ratio: 1;
  transform: translateY(-30%) translateX(-30%);
  background: transparent;

  svg {
    width: 1.8rem;
    height: auto;
  }

  @media (min-width: 730px) {
    top: calc(64px + 0.65rem);
  }
`;

const BtnRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  font-weight: 400;

  svg {
    display: block;
    height: 1.1rem;
    transform: translateY(-0.05rem);
    width: auto;
    margin-right: 0.4rem;
    path {
      fill: var(--color-charcoal);
    }
  }
`;

const IconThickChevronLeft = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="12"
    height="20"
    fill="none"
    viewBox="0 0 12 20"
  >
    <path
      fill="#212121"
      d="M.61 10.007c.006-.194.045-.37.117-.531.072-.16.182-.316.332-.465L9.376.959c.238-.238.531-.357.88-.357.232 0 .443.055.63.166.194.11.347.26.457.448a1.242 1.242 0 0 1-.216 1.536l-7.52 7.247 7.52 7.254c.26.266.39.568.39.905 0 .238-.057.451-.174.64-.11.188-.263.337-.456.448a1.176 1.176 0 0 1-.631.174c-.349 0-.642-.122-.88-.365l-8.317-8.052a1.514 1.514 0 0 1-.34-.465 1.322 1.322 0 0 1-.109-.531Z"
    />
  </svg>
);

const OverlayBackButton: FC<{
  fallbackUrl?: string;
  alwaysUseFallback?: boolean;
  show?: boolean;
}> = ({ fallbackUrl, show }) => {
  const [{ partnerSession }] = useBloc(AppViewCubit);
  const navigate = useGoToOrBack();
  const navigateUrl = fallbackUrl ?? "/app";

  const handleBack = () => {
    navigate(navigateUrl, {
      useBack: false
    });
  };

  if (!show) return null;

  try {
    return createPortal(
      <Button onClick={handleBack}>
        {partnerSession ? (
          <BtnRow>
            <IconThickChevronLeft /> <Translate msg="back" />
          </BtnRow>
        ) : (
          <ArrowLeftIcon />
        )}
      </Button>,
      document.getElementById("backButtonPortalTarget") as Element
    );
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
  } catch (error) {
    return null;
  }
};

export default OverlayBackButton;
